define("discourse/plugins/discourse-locations/discourse/components/location-label-container", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/runloop"], function (_exports, _decorators, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["location-label-container"],
    locationAttrs: [],
    geoAttrs: [],
    showMapToggle(geoLocation) {
      return geoLocation && this.siteSettings.location_topic_map;
    },
    opts(locationAttrs, geoAttrs) {
      let opts = {};
      if (locationAttrs) {
        opts["attrs"] = locationAttrs;
      }
      if (geoAttrs) {
        opts["geoAttrs"] = geoAttrs;
      }
      return opts;
    },
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    outsideClick(e) {
      if (!this.isDestroying && !($(e.target).closest(".map-expand").length || $(e.target).closest(".map-attribution").length || $(e.target).closest(".location-topic-map").length)) {
        this.set("showMap", false);
      }
    },
    actions: {
      showMap() {
        this.toggleProperty("showMap");
      }
    }
  }, [["method", "showMapToggle", [(0, _decorators.default)("topic.location.geo_location")]], ["method", "opts", [(0, _decorators.default)("locationAttrs", "geoAttrs")]]]));
});