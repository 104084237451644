define("discourse/plugins/discourse-locations/discourse/components/user-location", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/service", "@glimmer/tracking", "I18n", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "@ember/template-factory"], function (_exports, _component, _object, _component2, _service, _tracking, _I18n, _locationUtilities, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-location-widget">
    {{d-icon "map-marker-alt"}}
    <div class="location-label">
      {{this.userLocation}}
    </div>
    {{#if this.canShowMap}}
      <div class="map-wrapper">
        <button
          class="widget-button btn btn-default btn-show-map btn-small btn-icon-text"
          type="button"
          {{on "click" this.toggleMap}}
        >
          {{d-icon "far-map"}}
          {{#if this.showMapButtonLabel}}
            {{this.mapButtonLabel}}
          {{/if}}
        </button>
  
        {{#if this.showMap}}
          <div class="map-container small">
            <LocationsMap @mapType="user" @user={{@user}} />
          </div>
        {{/if}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "qkavke1u",
    "block": "[[[10,0],[14,0,\"user-location-widget\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"map-marker-alt\"],null]],[1,\"\\n  \"],[10,0],[14,0,\"location-label\"],[12],[1,\"\\n    \"],[1,[30,0,[\"userLocation\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"canShowMap\"]],[[[1,\"    \"],[10,0],[14,0,\"map-wrapper\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"widget-button btn btn-default btn-show-map btn-small btn-icon-text\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"toggleMap\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"far-map\"],null]],[1,\"\\n\"],[41,[30,0,[\"showMapButtonLabel\"]],[[[1,\"          \"],[1,[30,0,[\"mapButtonLabel\"]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showMap\"]],[[[1,\"        \"],[10,0],[14,0,\"map-container small\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@mapType\",\"@user\"],[\"user\",[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@user\"],false,[\"d-icon\",\"if\",\"on\",\"locations-map\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/components/user-location.hbs",
    "isStrictMode": false
  });
  class LocationMapComponent extends _component2.default {
    static #_ = dt7948.g(this.prototype, "siteSettings", [_service.inject]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    static #_2 = dt7948.g(this.prototype, "site", [_service.inject]);
    #site = (dt7948.i(this, "site"), void 0);
    static #_3 = dt7948.g(this.prototype, "showMap", [_tracking.tracked], function () {
      return false;
    });
    #showMap = (dt7948.i(this, "showMap"), void 0);
    get mapButtonLabel() {
      return _I18n.default.t(`location.geo.${this.showMap ? "hide" : "show"}_map`);
    }
    get showMapButtonLabel() {
      return this.args.formFactor !== "card" && !this.site.mobileView;
    }
    get userLocation() {
      let locationText = "";
      if (this.args.user && this.args.user.geo_location) {
        let format = this.siteSettings.location_user_profile_format.split("|");
        let opts = {};
        if (format.length && format[0]) {
          opts["geoAttrs"] = format;
          locationText = (0, _locationUtilities.geoLocationFormat)(this.args.user.geo_location, this.site.country_codes, opts);
        } else {
          locationText = this.args.user.geo_location.address;
        }
      }
      return locationText;
    }
    get canShowMap() {
      return !document.querySelector(".leaflet-container");
    }
    toggleMap() {
      this.showMap = !this.showMap;
    }
    static #_4 = dt7948.n(this.prototype, "toggleMap", [_object.action]);
  }
  _exports.default = LocationMapComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LocationMapComponent);
});